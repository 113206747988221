<div class="error-container bg-light">
  <div class="content">
    <a class="navbar-brand navbar-brand-override" routerLink="/">
      <!--<img [src]="plexeLogoUrl" alt="plexe" />-->
    </a>
    <div class="error-content">
      <h1 class="mb-3">Oops...</h1>
      <h3 class="more-bold">Something happened. Please try again.</h3>
      <button class="mt-5 plexe-btn-primary" routerLink="/landing">Go Home</button>
    </div>
  </div>
</div>
