import { NgModule, Optional, SkipSelf } from '@angular/core';
import { Router } from '@angular/router';
import { OriginationWriteApi } from './proxy/Write/originationWriteApi';
import { ApplicationInsightsService } from './services/application-insights.service';
import { DashboardService } from './services/dashboard.service';
import { SignalrService } from './services/signalr.service';
import { SharedModuleV2 } from '@app/shared/shared.module-v2';

@NgModule({
  imports: [SharedModuleV2],
  declarations: [],
  providers: [
    DashboardService,
    {
      provide: ApplicationInsightsService,
      useFactory: ApplicationInsightsFactory,
      deps: [Router]
    },
    {
      provide: SignalrService,
      useFactory: SignalRServiceFactory,
      deps: [OriginationWriteApi]
    }
  ],
  exports: [SharedModuleV2]
})
export class CoreModuleV2 {
  constructor(@Optional() @SkipSelf() parentModule: CoreModuleV2) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }
}

export function SignalRServiceFactory(writeApi: OriginationWriteApi) {
  return new SignalrService(window['appConfig']['writeApiUrl'], writeApi);
}

export function ApplicationInsightsFactory(router: Router) {
  return new ApplicationInsightsService(router);
}
