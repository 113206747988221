import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LoginGuard } from '@app/components/login-guard/login-guard';
import { NgbCustomOverridesModule } from '@app/components/ng-custom-typeahead/ng-custom-typeahead-override.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UnreadPipe } from './pipes/unread.pipe';
import { JsonFilterPipe } from './pipes/json-filter';
import { TruncatePipe } from './pipes/truncate.pipe';
import { DotsNavigatorComponent } from './components/dots-navigator/dots-navigator.component';
import { LoaderComponent } from './loader/loader.component';
import { ThankyouComponent } from '@app/components/thankyou/thankyou.component';
import { PlexeSingleSliderComponent } from './components/single-slider/single-slider.component';
import { UiSwitchComponent } from './components/ui-switch/ui-switch.component';
import { NumericDirective } from './directives/onlyNumber.directive';
import { HeaderV2Component } from './components/v2/header-v2/header-v2.component';
import { TourGuideComponent } from '@app/modules/v2/overview/overview-content/tour-guide/tour-guide.component';
import { HeaderModalWindowComponent } from './components/v2/header-modal-window/header-modal-window.component';
import { ApplicationProcessingMessageComponent } from './components/application-processing/application-processing.component';
import { AutoCompleteAddressComponent } from '@app/components/auto-complete-address/auto-complete-google-places.component';
import { NgModule } from '@angular/core';
import { LoanBankFeedAccountComponent } from './components/banking/bank-feed-account/loan-bank-feed-account/loan-bank-feed-account';
import { NewApplicationBankFeedAccountComponent } from './components/banking/bank-feed-account/new-application-bank-feed-account/new-application-bank-feed-account';
import { ApplicationBankFeedAccountComponent } from './components/banking/bank-feed-account/application-bank-feed-account/application-bank-feed-account';
import { ExistingComponent } from './components/new-application/slides/existing/existing.component';
import { ConnectivitySlideComponent } from './components/new-application/slides/existing/connectivity/connectivity';
import { LoanBankFeedSelectComponent } from './components/banking/bank-feed-select/loan-bank-feed/loan-bank-feed.component';
import { LoanBankFeedFindComponent } from './components/banking/bank-feed-find/loan-bank-feed/loan-bank-feed.component';
import { LoanBankFeedComponent } from './components/banking/bank-feed-details/loan-bank-feed/loan-bank-feed.component';
import { WelcomeModalComponent } from './components/v2/welcome-modal/welcome-modal.component';
import { PrimaryBtnComponent } from './components/v2/primary-btn/primary-btn.component';
import { SharedPipesModule } from './shared.pipes';

@NgModule({
  declarations: [
    PrimaryBtnComponent,
    // LoginModalWindowComponent,
    // LoaderComponent,
    // NoCommaPipe,
    // UnreadPipe,
    // JsonFilterPipe,
    // TruncatePipe,
    // LoanBankFeedAccountComponent,
    // NewApplicationBankFeedAccountComponent,
    // ApplicationBankFeedAccountComponent,
    WelcomeModalComponent,
    // ExistingComponent,
    // ConnectivitySlideComponent,
    // DotsNavigatorComponent,
    // PlexeSidebarComponent,
    // LoanBankFeedSelectComponent,
    // LoanBankFeedFindComponent,
    // LoanBankFeedComponent,
    // ThankyouComponent,
    // PlexeSingleSliderComponent,
    // UiSwitchComponent,
    // NumericDirective,
    // ApplicationProcessingMessageComponent,
    // PlexeProgressBarComponent,
    // AutoCompleteAddressComponent,
    // AutoCompleteIndustryComponent,
    TourGuideComponent,
    HeaderV2Component,
    HeaderModalWindowComponent
    // SystemNotificationMessageComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    NgbModule,
    NgbCustomOverridesModule,
    SharedPipesModule
  ],
  providers: [CurrencyPipe, LoginGuard],
  exports: [
    RouterModule,
    PrimaryBtnComponent,
    // LoginModalWindowComponent,
    WelcomeModalComponent,
    CommonModule,
    SharedPipesModule,
    // NoCommaPipe,
    // UnreadPipe,
    // JsonFilterPipe,
    // TruncatePipe,
    // DotsNavigatorComponent,
    // LoaderComponent,
    // PlexeSidebarComponent,
    NgbModule,
    // ThankyouComponent,
    // PlexeSingleSliderComponent,
    // UiSwitchComponent,
    // NumericDirective,
    FormsModule,
    // ApplicationProcessingMessageComponent,
    // PlexeProgressBarComponent,
    // AutoCompleteAddressComponent,
    // AutoCompleteIndustryComponent,
    TourGuideComponent,
    HeaderV2Component,
    HeaderModalWindowComponent
    // SystemNotificationMessageComponent
  ]
})
export class SharedModuleV2 {}
