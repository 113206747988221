import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoCommaPipe } from './pipes/no-comma.pipe'; // Import the pipe
import { TruncatePipe } from './pipes/truncate.pipe';

@NgModule({
  declarations: [NoCommaPipe, TruncatePipe], // Declare the pipe
  exports: [NoCommaPipe, TruncatePipe], // Export the pipe so it can be used in other modules
  imports: [CommonModule] // Import CommonModule to use Angular directives like ngIf, ngFor
})
export class SharedPipesModule {}
