import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { AppNavigatorService } from 'app/core/services/app-navigator.service';
import { Observable } from 'rxjs';
import { AuthenticationService } from '@app/core/services/authentication.service';

/**
 *  This guard is responsible for checking whether a Customer is connected
 *  and its details are available at the DataStore.
 */
@Injectable({ providedIn: 'root' })
export class CustomerAuthenticationGuard implements CanLoad {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private appNavigatorService: AppNavigatorService // private state: RouterStateSnapshot
  ) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    const pathIncludesV2 = route.data?.version?.includes('v2');
    let app = this.authenticationService.getSelectedAppData();
    if (pathIncludesV2) {
      var newApplication = sessionStorage.getItem('public_new_application_start_form_data');
      var isAuthenticated =
        this.authenticationService.isAuthenticated() && this.authenticationService.isAuthenticatedValid();
      if (
        !this.authenticationService.isCustomer() ||
        (!isAuthenticated && !app?.id && !app?.loanId && !JSON.parse(newApplication))
      ) {
        this.appNavigatorService.navigateLogin();
        return false;
      }

      if (route.path !== 'customer' && isAuthenticated && !app?.id && !app?.loanId && !JSON.parse(newApplication)) {
        this.appNavigatorService.navigateToCustomerHomeV2();
        return false;
      }
    } else {
      if (!this.authenticationService.isCustomer()) {
        this.appNavigatorService.navigateLogin();
        return false;
      }
    }
    return true;
  }
}
