import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DataStore } from '@app/DataStore';
import { ApplicationService } from 'app/core/services/application.service';
import { AuthenticationService } from 'app/core/services/authentication.service';
import { LoanService } from 'app/core/services/loan.service';

@Injectable()
export class DashboardService {
  constructor(
    private router: Router,
    private applicationService: ApplicationService,
    private authenticationService: AuthenticationService,
    private loanService: LoanService
  ) {}

  loadDashboardMetrics(applicationId: string) {
    if (applicationId === '') {
      var existingApp = this.authenticationService.getSelectedAppData();
      if (existingApp && existingApp.id) {
        applicationId = existingApp.id;
      } else {
        if (!!DataStore.customerData.loans && DataStore.customerData.loans.length > 0) {
          applicationId = DataStore.customerData.loans[0].applicationId;
        } else if (!!DataStore.customerData.readyApplications && DataStore.customerData.readyApplications.length > 0) {
          applicationId = DataStore.customerData.readyApplications[0].id;
        }
      }
    }

    if (applicationId != '' || applicationId !== undefined) {
      Promise.all([
        this.applicationService.loadDashboardAccountsMetrics(applicationId),
        this.applicationService.loadDashboardBusinessPerformanceMetrics(applicationId),
        this.applicationService.loadDashboardCashFlowsMetrics(applicationId),
        this.applicationService.loadDashboardInsightMetrics(applicationId),
        this.applicationService.loadDashboardLoanMetrics(applicationId),
        this.applicationService.loadDashboardOutgoingInoviceMetrics(applicationId)
      ]);
    }
  }
}
