<main class="w-100 h-100">
  <app-system-notification></app-system-notification>
  <div class="h-100" [ngClass]="{ 'system-message': systemMessage }">
    <router-outlet *ngIf="!pending"></router-outlet>
  </div>
  <div class="d-flex flex-spacer align-items-center loader-container" *ngIf="pending">
    <div class="mx-auto loading-container loader-content">
      <span class="text font-weight-bold flex-spacer text-black mb-0">Please wait</span>
      <p class="text-center plexe-para-text-1"></p>

      <img src="assets/svgs/loader.svg" class="loader-spin" />
    </div>
  </div>

  <footer *ngIf="!pending && whitelable" class="app-footer-whitelable">
    <p>
      This lending solution is powered by Plexe, LLC (“Plexe”). Plexe is not responsible for the accuracy, reliability,
      or suitability of the information and services from {{ partnerName }}. Plexe is a financial technology company
      that offers business lines of credit and other products.
    </p>

    <!-- <p>
    {{ partnerName == undefined ? 'This partner' : partnerName }}’s online lending solution is powered and enabled by
    Plexe, LLC (“Plexe”). Plexe is a financial technology company that operates as the technology platform, working
    capital provider and lending servicer. {{ partnerName == undefined ? 'This partner' : partnerName }}' is not
    affiliated with Plexe.
  </p>-->
  </footer>
  <!-- <app-plexe-loader *ngIf="isPageLoading"></app-plexe-loader> -->
</main>
