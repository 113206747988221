<div class="error-container bg-light">
  <div class="content">
    <a class="navbar-brand navbar-brand-override" routerLink="/">
      <!--<img [src]="plexeLogoUrl" alt="plexe" />-->
    </a>
    <div class="error-content">
      <h1 class="mb-3">404</h1>
      <h3 class="more-bold">We couldn't find this page</h3>
      <p class="plexe-dark-blue mb-0 mt-4">Sorry..... please click back in just a moment</p>
      <p class="plexe-dark-blue mb-0">Alternatively please restart or get in contact with us</p>
    </div>
  </div>
  <app-loader></app-loader>
</div>
