<div class="error-container bg-light">
  <div class="content">
    <a class="navbar-brand navbar-brand-override" routerLink="/">
      <!--<img [src]="plexeLogoUrl" alt="plexe" />-->
    </a>
    <div class="error-content">
      <h1 class="mb-3">Timeout</h1>
      <h3 class="more-bold">Your request has timed out</h3>
      <p class="plexe-dark-blue mb-0 mt-4">Sorry..... please try again</p>
      <button class="mt-5 plexe-btn-primary" routerLink="/landing">Go Home</button>
    </div>
  </div>
  <app-loader></app-loader>
</div>
