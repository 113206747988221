import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';

import { LoaderComponent } from './loader/loader.component';
import { UnreadPipe } from './pipes/unread.pipe';
import { PlexeCheckboxComponent } from './components/plexe-checkbox/plexe-checkbox.component';
import { PlexeSliderComponent } from './components/plexe-slider/plexe-slider.component';
import { NgbdSortableHeaderDirective } from './components/table-sortable-header';
import { NumericDirective } from './directives/onlyNumber.directive';
import { JsonFilterPipe } from './pipes/json-filter';
import { MonthDatePickerComponent } from './components/month-picker/month-picker';
import { NgbModule, NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { NgxMaskModule } from 'ngx-mask';
import { PublicHeaderComponent } from '@app/shared/components/public-header/header.component';
import { ExportToCsvComponent } from '@app/shared/components/export-to-csv/export-to-csv.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ApplicationStatusComponent } from '@app/shared/components/application-status/application-status.component';
import { PartnerNewApplicationIllustrativeScheduleSecuredComponent } from '@app/shared/components/new-application/slides/existing/illustrative-schedule-secured/illustrative-schedule';
import { DeclineComponent } from '@app/shared/components/new-application/slides/existing/decline/decline';
import { AccountingSoftwareSlideComponent } from '@app/shared/components/new-application/slides/existing/accounting-software/accounting-software';
import { CompleteComponent } from '@app/shared/components/new-application/slides/existing/complete/complete';
import { EmailTemplateSlideComponent } from '@app/shared/components/new-application/slides/existing/email-template/email-template';
import { ReferComponent } from '@app/shared/components/new-application/slides/existing/refer/refer';
import { ThankYouCustomerComponent } from '@app/shared/components/new-application/slides/existing/thank-you-customer/thank-you-customer.component';
import { IndicativeLineCreditSecuredSlideComponent } from '@app/shared/components/new-application/slides/existing/indicative-line-of-credit/indicative-line-of-credit';
import { CreditApprovalSlideComponent } from '@app/shared/components/new-application/slides/existing/credit-approval/credit-approval';
import { ExistingComponent } from '@app/shared/components/new-application/slides/existing/existing.component';
import { PartnerCreditApprovalFormComponent } from '@app/shared/components/new-application/slides/existing/credit-approval/credit-apporval-form/credit-approval-form';
import { ConnectivitySlideComponent } from '@app/shared/components/new-application/slides/existing/connectivity/connectivity';
import { PartnerNewApplicationIllustrativeScheduleUnsecuredComponent } from '@app/shared/components/new-application/slides/existing/illustrative-schedule-unsecured/illustrative-schedule';
import { UnsecuredCommissionComponent } from '@app/shared/components/new-application/slides/existing/potential-commission/unsecured/potential-commission-unsecured';
import { HowToConnectSlideComponent } from '@app/shared/components/new-application/slides/existing/how-to-connect/how-to-connect';
import { CompleteSimpleComponent } from '@app/shared/components/new-application/slides/existing/complete-simple/complete-simple.component';
import { SecuredCommissionComponent } from '@app/shared/components/new-application/slides/existing/potential-commission/secured/potential-commission-secured';
import { CreditFeaturesSlideComponent } from '@app/shared/components/new-application/slides/existing/credit-features/credit-features';
import { ApplicationBankFeedAccountComponent } from '@app/shared/components/banking/bank-feed-account/application-bank-feed-account/application-bank-feed-account';
import { LoanBankFeedAccountComponent } from '@app/shared/components/banking/bank-feed-account/loan-bank-feed-account/loan-bank-feed-account';
import { ApplicationBankFeedComponent } from '@app/shared/components/banking/bank-feed-details/application-bank-feed/application-bank-feed.component';
import { LoanBankFeedComponent } from '@app/shared/components/banking/bank-feed-details/loan-bank-feed/loan-bank-feed.component';
import { ApplicationBankFeedMfaComponent } from '@app/shared/components/banking/bank-feed-mfa/application-bank-feed-mfa/application-bank-feed-mfa';
import { LoanBankFeedMFAComponent } from '@app/shared/components/banking/bank-feed-mfa/loan-bank-feed-mfa/loan-bank-feed-mfa';
import { NewApplicationBankFeedAccountComponent } from '@app/shared/components/banking/bank-feed-account/new-application-bank-feed-account/new-application-bank-feed-account';
import { ApplicationProcessingMessageComponent } from '@app/shared/components/application-processing/application-processing.component';
import { GeneratingOfferComponent } from '@app/shared/components/new-application/slides/existing/generating-offer/generating-offer';
import { UiSwitchComponent } from '@app/shared/components/ui-switch/ui-switch.component';
import { RedirectComponent } from './components/new-application/slides/existing/redirect/redirect.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ApplicationBankFeedFindComponent } from './components/banking/bank-feed-find/application-bank-feed/application-bank-feed.component';
import { ApplicationBankFeedSelectComponent } from './components/banking/bank-feed-select/application-bank-feed/application-bank-feed.component';
import { LoanBankFeedFindComponent } from './components/banking/bank-feed-find/loan-bank-feed/loan-bank-feed.component';
import { LoanBankFeedSelectComponent } from './components/banking/bank-feed-select/loan-bank-feed/loan-bank-feed.component';
import { RangeSliderModule } from './components/range-slider/range-slider.module';
import { PlexeSingleSliderComponent } from './components/single-slider/single-slider.component';
import { PublicSidemenuComponent } from './components/public-sidemenu/sidemenu.component';
import { PublicPagesTemplateComponent } from './components/public-pages-template/public-pages-template.component';
import { SignComponent } from './components/new-application/slides/existing/sign/sign';
import { PublicCompleteComponent } from './components/new-application/slides/existing/public-complete/complete';
import { OpenAgreementComponent } from './components/new-application/slides/existing/open-agreement/open-agreement';
import { AgreementComponent } from './components/new-application/slides/existing/agreement/agreement';
import { PlexeSignaturePad } from './components/signature-pad/signature-pad';
import { PlexeSignatureField } from './components/signature-pad/signature-field/signature-field.component';
import { PublicDescriptionPanelComponent } from './components/public-description-panel/public-description-panel.component';
import { DotsNavigatorComponent } from './components/dots-navigator/dots-navigator.component';
import { AutoCompleteAddressComponent } from '@app/components/auto-complete-address/auto-complete-google-places.component';
import { NgbCustomOverridesModule } from '@app/components/ng-custom-typeahead/ng-custom-typeahead-override.module';
import { UsPhoneMaskDirective } from './directives/us-phone-mask.directive';
import { UsBusinessTaxIdMaskDirective } from './directives/us-business-tax-id-mask.directive';
import { YodleeCancelFeedComponent } from './components/banking/bank-feed-yodlee/yodlee-cancel-feed.component';
import { LoginGuard } from '@app/components/login-guard/login-guard';
import { CustomerAgreementComponent } from '../modules/customer/application/agreement/agreement';
import { AgreementTextAuComponent } from './components/common/agreement-text-au/agreement-text-au.component';
import { AgreementTextUsComponent } from './components/common/agreement-text-us/agreement-text-us.component';
import { SignTextAuComponent } from './components/common/sign-text-au/sign-text-au.component';
import { SignTextUsComponent } from './components/common/sign-text-us/sign-text-us.component';
import { SignTextUs2Component } from './components/common/sign-text-us-2/sign-text-us-2.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { BankReconnectComponent } from '@app/modules/public/bank-reconnect/bank-reconnect.component';
import { CustomerCommunicationPanelComponent } from '@app/modules/customer/shell/communication-panel/communication-panel.component';
import { BankFeedAddAccountComponent } from './components/banking/bank-feed-account-us/bank-feed-add-account/bank-feed-add-account.component';
import { BankSettingsComponent } from './components/banking/bank-settings/bank-settings.component';
import { BankFeedAccountUsComponent } from './components/banking/bank-feed-account-us/bank-feed-account-us.component';
import { FileDragNDropDirective } from './directives/file-drag-n-drop.directive';
import { SystemNotificationComponent } from './components/system-notification-message/system-notification.component';
import { ToNumberPipe } from './pipes/to-number.pipe';
import { ApplicationWithdrawFundsCreditCardRepaymentsComponent } from './components/withdraw-funds/credit-card/credit-card';
import { ApplicationWithdrawFundsFixedRepaymentsComponent } from './components/withdraw-funds/fixed-repayments/fixed-repayments';
import { ApplicationWithdrawFundsInvoicePaymentsComponent } from './components/withdraw-funds/invoice-payments/invoice-payments';
import { ApplicationWithdrawFundsPercentageCashIncomeComponent } from './components/withdraw-funds/percentage-cash-income/percentage-cash-income';
import { ApplicationWithdrawFundsQuickWithdrawalComponent } from './components/withdraw-funds/quick-withdrawal/quick-withdrawal';
import { ApplicationSelectPaymentOptionComponent } from './components/withdraw-funds/select-payment-option/select-payment-option';
import { ApplicationWithdrawFundsComponent } from './components/withdraw-funds/withdraw-funds.component';
import { HelpComponent } from './components/help/help.component';
import { WebcamModule } from 'ngx-webcam';
import { PendingChangesGuard } from './pending-changes-guard';
import { BankStatementUploadComponent } from './components/bank-statement-upload/bank-statement-upload.component';
import { SharedPipesModule } from './shared.pipes';

export const options: Partial<any> | (() => Partial<any>) = {};

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    RouterModule,
    SharedPipesModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(options),
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-bottom-full-width',
      preventDuplicates: true
    }),
    RangeSliderModule,
    NgbCustomOverridesModule,
    NgSelectModule,
    NgbProgressbarModule,
    WebcamModule
  ],
  providers: [CurrencyPipe, LoginGuard, PendingChangesGuard],
  declarations: [
    LoaderComponent,
    SpinnerComponent,
    UnreadPipe,
    JsonFilterPipe,
    PlexeCheckboxComponent,
    PlexeSliderComponent,
    PlexeSingleSliderComponent,
    PlexeSignaturePad,
    PlexeSignatureField,
    NgbdSortableHeaderDirective,
    NumericDirective,
    MonthDatePickerComponent,
    DotsNavigatorComponent,
    PublicHeaderComponent,
    PublicSidemenuComponent,
    ExportToCsvComponent,
    ApplicationStatusComponent,
    AccountingSoftwareSlideComponent,
    IndicativeLineCreditSecuredSlideComponent,
    PartnerNewApplicationIllustrativeScheduleUnsecuredComponent,
    PartnerNewApplicationIllustrativeScheduleSecuredComponent,
    CreditFeaturesSlideComponent,
    ConnectivitySlideComponent,
    HowToConnectSlideComponent,
    PartnerCreditApprovalFormComponent,
    CreditApprovalSlideComponent,
    CompleteComponent,
    ReferComponent,
    DeclineComponent,
    EmailTemplateSlideComponent,
    ExistingComponent,
    CompleteSimpleComponent,
    SecuredCommissionComponent,
    UnsecuredCommissionComponent,
    ThankYouCustomerComponent,
    ApplicationBankFeedAccountComponent,
    NewApplicationBankFeedAccountComponent,
    LoanBankFeedAccountComponent,
    ApplicationBankFeedComponent,
    LoanBankFeedComponent,
    ApplicationBankFeedMfaComponent,
    LoanBankFeedMFAComponent,
    ApplicationProcessingMessageComponent,
    GeneratingOfferComponent,
    UiSwitchComponent,
    RedirectComponent,
    ApplicationBankFeedSelectComponent,
    LoanBankFeedSelectComponent,
    ApplicationBankFeedFindComponent,
    LoanBankFeedFindComponent,
    PublicPagesTemplateComponent,
    PublicDescriptionPanelComponent,
    SignComponent,
    PublicCompleteComponent,
    OpenAgreementComponent,
    AgreementComponent,
    AutoCompleteAddressComponent,
    UsPhoneMaskDirective,
    UsBusinessTaxIdMaskDirective,
    YodleeCancelFeedComponent,
    CustomerAgreementComponent,
    AgreementTextAuComponent,
    AgreementTextUsComponent,
    SignTextAuComponent,
    SignTextUsComponent,
    SignTextUs2Component,
    BankReconnectComponent,
    CustomerCommunicationPanelComponent,
    BankFeedAddAccountComponent,
    BankSettingsComponent,
    BankFeedAccountUsComponent,
    FileDragNDropDirective,
    SystemNotificationComponent,
    ToNumberPipe,
    ApplicationWithdrawFundsComponent,
    ApplicationWithdrawFundsCreditCardRepaymentsComponent,
    ApplicationWithdrawFundsFixedRepaymentsComponent,
    ApplicationWithdrawFundsInvoicePaymentsComponent,
    ApplicationWithdrawFundsPercentageCashIncomeComponent,
    ApplicationWithdrawFundsQuickWithdrawalComponent,
    ApplicationSelectPaymentOptionComponent,
    HelpComponent,
    BankStatementUploadComponent
  ],
  exports: [
    CommonModule,
    RangeSliderModule,
    LoaderComponent,
    NgbModule,
    SharedPipesModule,
    UnreadPipe,
    JsonFilterPipe,
    PlexeCheckboxComponent,
    PlexeSliderComponent,
    PlexeSingleSliderComponent,
    PlexeSignaturePad,
    PlexeSignatureField,
    NgbdSortableHeaderDirective,
    NumericDirective,
    MonthDatePickerComponent,
    DotsNavigatorComponent,
    NgxMaskModule,
    PublicHeaderComponent,
    PublicSidemenuComponent,
    ExportToCsvComponent,
    SpinnerComponent,
    ApplicationStatusComponent,

    FormsModule,
    ReactiveFormsModule,
    ApplicationProcessingMessageComponent,
    UiSwitchComponent,
    ApplicationBankFeedSelectComponent,
    LoanBankFeedSelectComponent,
    ApplicationBankFeedFindComponent,
    LoanBankFeedFindComponent,
    PublicPagesTemplateComponent,
    PublicDescriptionPanelComponent,
    SignComponent,
    PublicCompleteComponent,
    OpenAgreementComponent,
    AgreementComponent,
    AutoCompleteAddressComponent,
    UsPhoneMaskDirective,
    UsBusinessTaxIdMaskDirective,
    YodleeCancelFeedComponent,
    CustomerAgreementComponent,
    AgreementTextAuComponent,
    AgreementTextUsComponent,
    SignTextAuComponent,
    SignTextUsComponent,
    SignTextUs2Component,
    NgSelectModule,
    BankReconnectComponent,
    CustomerCommunicationPanelComponent,
    BankFeedAddAccountComponent,
    BankFeedAccountUsComponent,
    FileDragNDropDirective,
    SystemNotificationComponent,
    ToNumberPipe,
    ApplicationWithdrawFundsComponent,
    ApplicationWithdrawFundsCreditCardRepaymentsComponent,
    ApplicationWithdrawFundsFixedRepaymentsComponent,
    ApplicationWithdrawFundsInvoicePaymentsComponent,
    ApplicationWithdrawFundsPercentageCashIncomeComponent,
    ApplicationWithdrawFundsQuickWithdrawalComponent,
    ApplicationSelectPaymentOptionComponent,
    NgbProgressbarModule,
    WebcamModule,
    BankStatementUploadComponent
  ]
})
export class SharedModule {}
