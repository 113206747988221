import { ChangePasswordComponent } from './modules/public/change-password/change-password.component';
import { ErrorHandler, Injector, NgModule } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';

import { environment } from '@env/environment';
import { AppComponent } from './app.component';
import { OriginationReadApi } from '@app/core/proxy/Read/originationReadApi';
import { OriginationWriteApi } from '@app/core/proxy/Write/originationWriteApi';
import { TokenHolderService } from '@app/core/services/token-holder.service';
import { AuthorizationAPI } from '@app/core/proxy/Auth/authorizationAPI';
import { Router, RouteReuseStrategy, RouterModule } from '@angular/router';
import { InterceptorHttpClient } from '@app/core/http/InterceptorHttpClient';
import { BrowserModule } from '@angular/platform-browser';
import { CoreModule } from '@app/core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_ROUTES } from '@app/routes';
import { ThankyouComponent } from '@app/components/thankyou/thankyou.component';
import { PasswordResetComponent } from '@app/components/password-reset/password-reset.component';
import { LoginComponent } from '@app/components/login/login.component';
import { LandingComponent } from '@app/components/landing/landing.component';
import { ForgotPasswordComponent } from '@app/modules/public/forgot-password/forgot-password.component';
import { Error404Component } from '@app/components/errors/error-404/error.component';
import { ApplicationProcessingComponent } from '@app/components/errors/application-processing/application-processing.component';
import { TimeoutErrorComponent } from '@app/components/errors/timeout-error/timeout-error.component';
import { GeneralErrorComponent } from '@app/components/errors/general/error.component';
import { TwoFactorComponent } from '@app/components/two-factor/two-factor.component';
import { HttpClientModule } from '@angular/common/http';
import { LogoutRedirectComponent } from '@app/components/logout-redirect/logout-redirect.component';
import { FirstLoginComponent } from './components/first-login/first-login.component';
import { LoginSmsComponent } from './components/login-sms/login-sms.component';
import { ErrorHandlerService } from './core/services/application-insights.service';
import { PasswordExpireComponent } from './components/password-expire/password-expire.component';
import { CoreModuleV2 } from './core/core.module-v2';
import { RouteReusableStrategy } from './shared/route-reuse-strategy';

const providers: any[] = [
  {
    provide: OriginationReadApi,
    useFactory: OriginationReadApiFactory,
    deps: [TokenHolderService, Router]
  },
  {
    provide: OriginationWriteApi,
    useFactory: OriginationWriteApiFactory,
    deps: [TokenHolderService, Router]
  },
  {
    provide: AuthorizationAPI,
    useFactory: AuthorizationApiFactory,
    deps: [TokenHolderService, Router]
  },
  {
    provide: ErrorHandler,
    useClass: ErrorHandlerService
  }
];

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
    CoreModule,
    CoreModuleV2,
    RouterModule.forRoot(APP_ROUTES)
  ],
  declarations: [
    AppComponent,
    ThankyouComponent,
    PasswordResetComponent,
    LoginComponent,
    LandingComponent,
    ForgotPasswordComponent,
    Error404Component,
    ApplicationProcessingComponent,
    TimeoutErrorComponent,
    GeneralErrorComponent,
    TwoFactorComponent,
    LogoutRedirectComponent,
    FirstLoginComponent,
    LoginSmsComponent,
    PasswordExpireComponent,
    ChangePasswordComponent
  ],
  providers: [providers, ErrorHandlerService, { provide: RouteReuseStrategy, useClass: RouteReusableStrategy }],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule {}

export function AuthorizationApiFactory(tokenHolder: TokenHolderService, router: Router) {
  return new AuthorizationAPI(tokenHolder.getToken(), {
    baseUri: window['appConfig']['authApiUrl'],
    httpClient: new InterceptorHttpClient(router)
  });
}

export function OriginationReadApiFactory(tokenHolder: TokenHolderService, router: Router) {
  return new OriginationReadApi(tokenHolder.getToken(), {
    baseUri: window['appConfig']['readApiUrl'],
    httpClient: new InterceptorHttpClient(router)
  });
}

export function OriginationWriteApiFactory(tokenHolder: TokenHolderService, router: Router) {
  return new OriginationWriteApi(tokenHolder.getToken(), {
    baseUri: window['appConfig']['writeApiUrl'],
    httpClient: new InterceptorHttpClient(router)
  });
}
